import React from 'react';

import ListStyled from '@views/components/list/ListStyled';
import { companyInfo } from '@mocks/sample-data';
import { CONTENT } from '../intl';

export const Support = React.memo(() => (
  <div className="mb-3">
    <ListStyled>
      <ListStyled.Item>
        <img
          src="/assets/icon/phone.png"
          width={25}
          height={25}
          alt={companyInfo.phone}
          className="mr-2"
        />
        <span>{CONTENT.ORDER_SUPPORT}: </span>
        <a href={`tel:${companyInfo.phone}`} className="list__link">
          {companyInfo.phone}
        </a>
      </ListStyled.Item>
      <ListStyled.Item>
        <img
          src="/assets/icon/gmail.png"
          width={25}
          height={25}
          alt={companyInfo.email}
          className="mr-2"
        />
        <span>{CONTENT.EMAIL}: </span>
        <a href={`mailto:${companyInfo.email}`} className="list__link">
          {companyInfo.email}
        </a>
      </ListStyled.Item>
    </ListStyled>
  </div>
));

export default Support;
