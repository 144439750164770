export const percentage = (
  firstNumber: number,
  secondNumber: number,
  fixed: number = 0
) => {
  if (secondNumber === 0) {
    return 0;
  }

  return (((secondNumber - firstNumber) / secondNumber) * 100).toFixed(fixed);
};

export default percentage;
