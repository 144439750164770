import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import CardAction from '@views/components/product/card/CardAction';

const IconEye =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHZpZXdCb3g9IjAgMCAyMiAxNC42NjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUzMS41IC0xNjEwKSI+PHBhdGggZmlsbD0iI2ZmZiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTMxLjUgMTYwNS41KSIgZD0iTTIxLjg2NyAxMS4yNzZhMTIuNzQ2IDEyLjc0NiAwIDAgMC02Ljk0Ny02LjExNEExMS4yNzUgMTEuMjc1IDAgMCAwIDExIDQuNSAxMi4yNTIgMTIuMjUyIDAgMCAwIC4xMzMgMTEuMjc2YTEuMjM2IDEuMjM2IDAgMCAwIDAgMS4xMTVBMTIuMjUgMTIuMjUgMCAwIDAgMTEgMTkuMTY3YTEyLjI1MiAxMi4yNTIgMCAwIDAgMTAuODY3LTYuNzc2IDEuMjM2IDEuMjM2IDAgMCAwIDAtMS4xMTV6TTExIDE3LjMzM2E1LjUgNS41IDAgMSAxIDUuNS01LjUgNS41IDUuNSAwIDAgMS01LjUgNS41eiIvPjxjaXJjbGUgZmlsbD0iI2ZmZiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTM5IDE2MTQpIiBjeD0iMy41IiBjeT0iMy41IiByPSIzLjUiLz48L2c+PC9zdmc+';
const IconCart =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHZpZXdCb3g9IjAgMCAzMCAyMy4wNzciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMS41IC0zMi41KSIgZmlsbD0iI2ZmZiI+PGNpcmNsZSB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjEuNDYgNTIuNDEzKSIgY3g9IjEuNTgyIiBjeT0iMS41ODIiIHI9IjEuNTgyIi8+PGNpcmNsZSB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzQuNzIgNTIuNDEzKSIgY3g9IjEuNTgyIiBjeT0iMS41ODIiIHI9IjEuNTgyIi8+PHBhdGggdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCkiIGQ9Ik0xNDEuMjQxLDM2LjM3OWExLjA4MiwxLjA4MiwwLDAsMC0uODIyLS4zNzhoLTIxLjRsLS41MS0yLjYyOWExLjA4MSwxLjA4MSwwLDAsMC0xLjA2Mi0uODczaC00Ljg2NWExLjA3NywxLjA3NywwLDEsMCwwLDIuMTU1aDMuOTczbDEuMjcyLDYuNTU4LDEuNTI1LDkuNWExLjA4MSwxLjA4MSwwLDAsMCwxLjA2OC45MDdoMTcuODM4YTEuMDgxLDEuMDgxLDAsMCwwLDEuMDY4LS45MDdsMi4xNjItMTMuNDY2QTEuMDc2LDEuMDc2LDAsMCwwLDE0MS4yNDEsMzYuMzc5Wm0tMy45MDYsMTMuMDg4SDEyMS4zNDFsLTEuODE2LTExLjMxMmgxOS42MjZaIi8+PC9nPjwvc3ZnPg==';
import { CARD_TEXT } from '../../intl';

const ActionWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .btn {
    border: 0 !important;
    outline: none !important;
    border-radius: none !important;
    box-shadow: none !important;
  }
`;

const BuyAction = styled(Button)`
  font-size: 1.125rem;
`;

const ViewAction = styled(Button)`
  background: url(${IconEye}) center center no-repeat;
  background-size: 20px;
`;

const CartAction = styled(Button)`
  background: url(${IconCart}) center center no-repeat;
  background-size: 20px;
`;

const ActionIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80px;
  & > .btn {
    width: 50%;
  }
`;

const ActionButton = styled(Button)`
  min-width: 180px;
`;

export const ProductAction = React.memo<any>(
  ({ onBuyClick, onViewClick, onAddCartClick, disabled, isSubmitting }) => (
    <CardAction>
      <ActionWrapper>
        <BuyAction onClick={onBuyClick} disabled={disabled}>
          {CARD_TEXT.BUY}
        </BuyAction>
        <ActionIconWrapper>
          <ViewAction onClick={onViewClick} disabled={isSubmitting} />
          <CartAction onClick={onAddCartClick} disabled={disabled} />
        </ActionIconWrapper>
      </ActionWrapper>
    </CardAction>
  )
);

ProductAction.displayName = 'ProductAction';

export const ProductActionInRow = React.memo<any>(
  ({ onBuyClick, onAddCartClick, disabled }) => (
    <div className="product-card__row-action">
      <div>
        <ActionButton
          type="submit"
          variant="outline-primary"
          onClick={onAddCartClick}
          disabled={disabled}
        >
          Thêm vào giỏ hàng
        </ActionButton>
      </div>
      <div>
        <ActionButton
          type="button"
          variant="primary"
          onClick={onBuyClick}
          disabled={disabled}
        >
          Mua ngay
        </ActionButton>
      </div>
    </div>
  )
);
ProductActionInRow.displayName = 'ProductActionInRow';

export default ProductAction;
