import React, { FC, useState, memo } from 'react';

import styled from 'styled-components';
import { Carousel, Card } from 'react-bootstrap';
import LazyImage from '@views/components/lazy-image';
import LazyZoomImage from '@views/components/lazy-zoom-image/magnify';

import classNames from 'classnames';
import _get from 'lodash/get';
import Video, { VideoProps } from './video';
import { Support } from './support';

export interface GalleryProps {
  data: Array<string | VideoProps>;
}

const CarouselItem = styled(Carousel.Item)`
  img {
    object-fit: contain;
  }
`;

export const Gallery: FC<GalleryProps> = ({ data }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="product__gallery">
      <Carousel
        key={`product__gallery-${index}`}
        activeIndex={index}
        indicators={false}
        controls={false}
        onSelect={handleSelect}
        interval={null}
        slide={false}
        pause="hover"
      >
        {data.map((item: string | VideoProps, i: number) => {
          if (typeof item === 'string') {
            return (
              <CarouselItem
                key={`${item}-${i}`}
                className="product__carousel-item"
              >
                <LazyZoomImage
                  key={`${item}-image-${i}`}
                  className="d-block w-100"
                  src={item}
                  largeSrc={item}
                  alt={item}
                />
              </CarouselItem>
            );
          }

          return (
            <Carousel.Item
              key={`${_get(item, 'id')}-${i}`}
              className="product__carousel-item"
            >
              <Video {...item} stop={index !== i} />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div className="carousel product-carousel">
        <ol className="carousel-indicators">
          {data.map((item: string | VideoProps, i: number) => {
            if (typeof item === 'string') {
              return (
                <li
                  key={`${item}-${i}`}
                  onClick={(event) => handleSelect(i, event)}
                  className={classNames({ active: index === i })}
                >
                  <Card>
                    <LazyImage className="card-img-top" src={item} alt={item} />
                  </Card>
                </li>
              );
            }

            return (
              <li
                key={`${_get(item, 'id')}-${i}`}
                onClick={(event) => handleSelect(i, event)}
                className={classNames({ active: index === i })}
              >
                <Card>
                  <LazyImage
                    className="card-img-top"
                    src={_get(item, 'thumbnail')}
                    alt={_get(item, 'thumbnail')}
                  />
                </Card>
              </li>
            );
          })}
        </ol>
      </div>
      <div className="d-none d-md-block">
        <Support />
      </div>
    </div>
  );
};

export default memo(Gallery);
