import Cookies from 'universal-cookie';
import _addDays from 'date-fns/addDays';

export const RECENT_KEY = 'fast_recently_product_key';
export const LIMIT = 150;
export const EXPIRED_DATE = 14;
export function tracking(cookies: Cookies, productId: any) {
  const recentlyProducts: any[] = get(cookies);
  if (productId && !recentlyProducts.includes(productId)) {
    recentlyProducts.unshift(productId);

    const length =
      recentlyProducts.length > LIMIT ? LIMIT : recentlyProducts.length;

    cookies.set(RECENT_KEY, recentlyProducts.slice(0, length), {
      path: '/',
      expires: _addDays(Date.now(), EXPIRED_DATE),
    });
  }
}

export function get(cookies: Cookies): Array<any> {
  return cookies.get(RECENT_KEY) || [];
}
