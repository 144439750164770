import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const TRACKING_PRODUCT_MUTATION = gql`
  mutation TrackingRecentProduct($id: ID, $customerId: ID) {
    trackingRecentProduct(id: $id, customerId: $customerId)
  }
`;

export const useTrackingProduct = () => {
  return useMutation(TRACKING_PRODUCT_MUTATION);
};

export default useTrackingProduct;
