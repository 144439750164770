import React, { FunctionComponent } from 'react';

import { Formik, Form } from 'formik';
import { FormLabel, FormControl, Button } from 'react-bootstrap';

import LikeButton from '@views/modules/common/components/like-button';
import ProductAction from '@views/components/product/ProductAction';
import styled from 'styled-components';
import { MODAL_TEXT } from '../../intl';

export interface Values {
  quantity: number;
  isRedirect: false;
}

const initialValues: Values = {
  quantity: 1,
  isRedirect: false,
};

const ActionButton = styled(Button)`
  min-width: 180px;
  margin-right: 24px;
`;

const ProductActionWrapper = styled(ProductAction)`
  display: flex;
  flex-wrap: wrap;
`;

export const Action: FunctionComponent<any> = ({
  id,
  isAuthorized,
  quantityAvailable,
  onSubmit,
}) => {
  const increaseQuantity = (quantity: number) => {
    return quantity + 1;
  };

  const decreaseQuantity = (quantity: number) => {
    const value = quantity - 1;

    return value < 1 ? 1 : value;
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        submitForm,
      }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <ProductAction>
            <ProductAction.Item>
              <div className="input-number product__quantity">
                <FormLabel className="input-number__label">
                  {MODAL_TEXT.QUANTITY}:
                </FormLabel>
                <Button
                  type="button"
                  className="input-number__sub"
                  onClick={() =>
                    setFieldValue('quantity', decreaseQuantity(values.quantity))
                  }
                />
                <FormControl
                  type="number"
                  name="quantity"
                  className="input-number__input"
                  value={`${values.quantity}`}
                  onChange={(event) => {
                    const value = parseInt(event.target.value);
                    setFieldValue('quantity', value <= 0 ? 1 : value);
                  }}
                />
                <Button
                  type="button"
                  className="input-number__add"
                  onClick={() =>
                    setFieldValue('quantity', increaseQuantity(values.quantity))
                  }
                />
              </div>
            </ProductAction.Item>
          </ProductAction>

          <ProductActionWrapper>
            <ProductAction.Item>
              <ActionButton
                type="button"
                variant="outline-primary"
                disabled={isSubmitting}
                size="lg"
                onClick={() => {
                  setFieldValue('isRedirect', true);
                  submitForm();
                }}
              >
                {MODAL_TEXT.BUY}
              </ActionButton>
            </ProductAction.Item>

            <ProductAction.Item>
              <ActionButton
                type="submit"
                variant="primary"
                disabled={isSubmitting}
                size="lg"
              >
                {MODAL_TEXT.ADD_TO_CART}
              </ActionButton>
            </ProductAction.Item>
            <ProductAction.Item>
              <LikeButton productId={id} isAuthorized={isAuthorized} />
            </ProductAction.Item>
          </ProductActionWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default Action;
