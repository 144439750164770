import React, { Fragment } from 'react';

export const CONTENT = {
  QUANTITY: 'Số lượng',
  ADD_TO_CART: 'Thêm vào giỏ hàng',
  BUY: 'Mua ngay',
  SIDEBAR_CONTENT: (
    <Fragment>
      <ul>
        {/* <li>Miễn phí giao hàng khu vực HCM</li> */}
        <li>
          Hưởng mức chiết khấu <a href="#">5-15%</a>
        </li>
        <li>
          Nhiều phần <a href="#">quà tặng hấp dẫn</a> thay đổi liên tục theo
          từng tháng
        </li>
        <li>Những khách hàng V.I.P sẽ được ưu tiên </li>
        <li>Giao hàng nhanh, thường sau 2h từ khi nhận được đơn hoàn chỉnh</li>
        <li>Đổi trả hàng đúng theo yêu cầu</li>
      </ul>
    </Fragment>
  ),
  ONLINE_SUPPORT: 'Hỗ trợ online',
  ORDER_SUPPORT: 'Hotline đặt hàng',
  EMAIL: 'Email',
  OUT_OF_STOCK: 'Hết hàng',
  CONTACT: 'Liên hệ',
  CONTACT_PRICE_MESSAGE:
    'Văn Phòng Phẩm Fast sẽ liên hệ trực tiếp với khách hàng để xác nhận giá bán sản phẩm sau khi đơn hàng được đặt thành công',
};

export const DESCRIPTION = {
  TITLE: 'CHI TIẾT SẢN PHẨM',
};

export const INCENTIVE = {
  TITLE: 'CHÍNH SÁCH ƯU ĐÃI',
  CONTENT: (
    <Fragment>
      <ul>
        <li>Tổng đơn hàng &gt; 1 triệu: Chiết khấu 5% + Quà tặng</li>
        <li>Tổng đơn hàng &gt; 3 triệu: Chiết khấu 7% + Quà tặng</li>
        <li>Tổng đơn hàng &gt; 5 triệu: Chiết khấu 10% + Quà tặng</li>
        <li>Tổng đơn hàng &gt; 8 triệu: Chiết khấu 15% + Quà tặng</li>
      </ul>
      {/* <div><em>(Chiết khấu ngoài hóa đơn trừ giấy in)</em></div> */}
    </Fragment>
  ),
};
