import React, { Fragment, useMemo } from 'react';
import CardPrice from '@views/components/product/card/CardPrice';
import { CARD_TEXT } from '../../intl';
import { formatCurrencyVN as formatCurrency } from '@helpers/format-currency';
import _percentage from '@helpers/percentage';

type ProductPriceProps = {
  quantityAvailable: number;
  isContact: boolean;
  price: number;
  listPrice: number;
  sale: boolean;
};

const ProductPrice = React.memo<ProductPriceProps>(
  ({ quantityAvailable, isContact, price, listPrice, sale }) => {
    const contentPrice = (() => {
      if (quantityAvailable <= 0) {
        return (
          <CardPrice.NewPrice>{CARD_TEXT.OUT_OF_STOCK}</CardPrice.NewPrice>
        );
      }

      if (isContact) {
        return <CardPrice.NewPrice>{CARD_TEXT.CONTACT}</CardPrice.NewPrice>;
      }

      return (
        <Fragment>
          <CardPrice.NewPrice>{formatCurrency(price)}</CardPrice.NewPrice>
          {sale && (
            <Fragment>
              <CardPrice.BadgeSale>
                <span>-{_percentage(price, listPrice)}%</span>
              </CardPrice.BadgeSale>
              <CardPrice.OldPrice>
                {formatCurrency(listPrice)}
              </CardPrice.OldPrice>
            </Fragment>
          )}
        </Fragment>
      );
    })();

    return <CardPrice>{contentPrice}</CardPrice>;
  }
);
ProductPrice.displayName = 'ProductPrice';

export default ProductPrice;
