import { useRouter } from 'next/router';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { fromJS, Record } from 'immutable';
import Product from '@graphql/fragments/product.gql';
import ProductCategory from '@graphql/fragments/product-category.gql';
import { IProduct } from '@server/data/models/product';
import _getId from '@helpers/get-id-from-slug';

export const QUERY = gql`
  query ProductFetch($id: Int) {
    product(id: $id) {
      ...ProductEntity
      attributes {
        productCode
        productLength
        productWidth
        productHeight
        productWeight
        metaTitle
        metaKeywords
        metaDescription
        name
        description
        listPrice
        price
        image
        galleries
        brand {
          id
          value
          position
        }
      }
      categories {
        ...ProductCategoryEntity
      }
    }
  }
  ${Product}
  ${ProductCategory}
`;

export type UseProductResponse = {
  product: Record<IProduct | null>;
  loading: boolean;
  error: boolean;
};

/************************************
 * Only use it in product detail page,
 *
 * @returns UseProductResponse
 */
export const useProduct = (): UseProductResponse => {
  const router = useRouter();
  const id = _getId(router.query.slug);
  const { data, loading, error } = useQuery(QUERY, {
    variables: { id },
  });
  const product = !!data ? fromJS(data.product) : fromJS({});

  return {
    product,
    loading,
    error: !!error,
  };
};

/************************************
 * Use to get product detail info
 *
 * @returns UseProductResponse
 */
export const useProductById = (id): UseProductResponse => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { id },
  });
  const product = !!data ? fromJS(data.product) : fromJS({});

  return {
    product,
    loading,
    error: !!error,
  };
};

export default useProduct;
