import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player/lazy';
import _get from 'lodash/get';

export type VideoProps = {
  id: string;
  type: 'shopee' | 'youtube';
  value: string;
  thumbnail: string;
  stop?: boolean;
};

export const Video = ({ id, type, value, thumbnail, stop }) => {
  // const client = useApolloClient();
  const ref = useRef(null);
  const [url, setUrl] = useState(null);
  const [playing, setPlaying] = useState(false);

  // const initVideoUrl = useCallback(async () => {
  //   if (type === 'youtube') {
  //     setUrl(value);
  //     setPlaying(true);
  //     return;
  //   }

  //   setPlaying(true);
  //   setUrl(await fetchShopeeUrl(value, client));
  // }, [type, value]);

  const handlePlay = () => setPlaying(true);
  const handlePause = () => setPlaying(false);
  const handleEnd = () => setPlaying(false);

  const togglePlaying = useCallback(() => {
    setPlaying(!playing);
  }, [playing]);

  useEffect(() => {
    if (!!value) {
      setUrl(value);
      setPlaying(true);
    }
  }, [value]);

  useEffect(() => {
    setPlaying(!stop);
  }, [stop]);

  return (
    <div className="player-wrapper" style={{ background: '#FAFAFA' }}>
      <ReactPlayer
        ref={ref}
        className="react-player"
        width="100%"
        height="300px"
        url={url}
        controls
        muted
        playing={playing}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnd}
      />
      {!playing && (
        <svg
          className="play-icon"
          onClick={togglePlaying}
          enableBackground="new 0 0 15 15"
          viewBox="0 0 15 15"
          x="0"
          y="0"
        >
          <g opacity=".54">
            <g>
              <circle cx="7.5" cy="7.5" fill="#040000" r="7.3"></circle>
              <path
                d="m7.5.5c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5z"
                fill="#ffffff"
              ></path>
            </g>
          </g>
          <path
            clipRule="evenodd"
            d="m10.2 5.3c.5.7.8 1.4.8 2.2 0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5 1.6-3.5 3.5-3.5v.5c-1.6 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3c0-.7-.2-1.3-.6-1.8-.1-.1-.1-.1-.1-.1-.1-.1-.1-.3 0-.4s.3-.1.4.1c0-.1 0 0 0 0z"
            fill="#ffffff"
            fillRule="evenodd"
          ></path>
          <path
            clipRule="evenodd"
            d="m7.5 2.9c0-.1.1-.1.1-.1l1.4 1.5-1.4 1.4c0 .1-.1.1-.1 0z"
            fill="#ffffff"
            fillRule="evenodd"
          ></path>
        </svg>
      )}
    </div>
  );
};

export default Video;
