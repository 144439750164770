import React, {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import useIntersectionObserver from '@hooks/use-intersection-observer';

import CardBody from '@views/components/product/card/CardBody';
import CardInfo from '@views/components/product/card/CardInfo';
import CardName from '@views/components/product/card/CardName';
import CardRating from '@views/components/product/card/CardRating';
import CardText from '@views/components/product/card/CardText';
import Rating from '@views/components/rating';

import LikeButton from '@views/modules/common/components/like-button-listing';
import ProductImage from './image';
import ProductPrice from './price';
import { ProductAction, ProductActionInRow } from './action';

import { CARD_TEXT } from '../../intl';
import { INTERNAL_URI, productHref } from '@helpers/route';
import _scrollToTop from '@helpers/scroll-top';
import _isContactPrice from '@helpers/is-contact-price';

export type TProductCardProps = {
  id: string | number;
  name?: string;
  image?: string;
  imageSet?: string;
  price?: number;
  listPrice?: number;
  isSubmitting?: boolean;
  quantityAvailable: number;
  hasVideo?: boolean;
  isContactProduct?: boolean;
  isInterested?: boolean;
  isRow?: boolean;
  onBuyClick?: (event: MouseEvent) => void;
  onAddCartClick?: (event: MouseEvent) => void;
  onViewClick?: (event: MouseEvent) => void;
  dataCategory?: string;
  dataAction?: string;
  dataLabel?: string;
  dataClassName?: string;
  nofollow?: boolean;
};

const BoxContainer = styled.div`
  position: relative;

  .like-button {
    position: absolute;
    top: 0;
    right: -10px;
  }
`;

const MAX_RATING = 5;

const ProductCard: FunctionComponent<TProductCardProps> = (
  props
): JSX.Element => {
  const {
    id,
    name,
    image,
    imageSet,
    price,
    listPrice,
    quantityAvailable,
    isSubmitting,
    hasVideo,
    isContactProduct: isContact,
    isInterested,
    isRow,
    onBuyClick,
    onAddCartClick,
    onViewClick,
    dataClassName,
    dataAction,
    dataCategory,
    dataLabel,
    nofollow = false,
  } = props;

  const sale: number = !!listPrice ? listPrice - price : 0;
  const url = productHref(id, name);
  const star = MAX_RATING;

  // const isContact = _isContactPrice(price);
  const disabled = isSubmitting || quantityAvailable === 0;

  const handleDataLayer = useCallback(() => {
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          products: [
            {
              name: name, // Name or ID is required.
              id: id,
              price: price,
              brand: '',
              category: dataCategory,
              variant: '',
              position: 0,
            },
          ],
        },
      },
    });
  }, [name, price, id]);

  const handleImpression = useCallback(() => {
    // console.log('impression', props.name);
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      ecommerce: {
        currencyCode: 'VND', // Local currency is optional.
        impressions: [
          {
            name: props.name, // Name or ID is required.
            id: props.id,
            price: props.price,
            brand: '',
            category: props.dataCategory,
            variant: '',
            position: 0,
          },
        ],
      },
    });
  }, [props]);

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { threshold: 0.5 });
  const isVisible = !!entry?.isIntersecting;
  const linkProps = {
    rel: nofollow ? 'nofollow' : undefined,
  };

  useEffect(() => {
    handleImpression();
  }, [isVisible, handleImpression]);

  return (
    <div ref={ref} className="product-card">
      <Link href={INTERNAL_URI.PRODUCT} as={url} passHref>
        <ProductImage
          alt={name}
          image={image}
          hasVideo={hasVideo}
          className={dataClassName}
          data-category={dataCategory}
          data-action={dataAction}
          data-label={dataLabel}
          onClick={handleDataLayer}
          {...linkProps}
        />
      </Link>
      <CardBody>
        <CardInfo>
          <CardName>
            <Link href={INTERNAL_URI.PRODUCT} as={url}>
              <a
                aria-label={name}
                title={name}
                className={dataClassName}
                data-category={dataCategory}
                data-action={dataAction}
                data-label={dataLabel}
                onClick={handleDataLayer}
                {...linkProps}
              >
                {name}
              </a>
            </Link>
          </CardName>
          <BoxContainer>
            <ProductPrice
              quantityAvailable={quantityAvailable}
              isContact={isContact}
              price={price}
              listPrice={listPrice}
              sale={!!sale}
            />
            <div className="like-button">
              <LikeButton liked={isInterested} productId={id} />
            </div>
          </BoxContainer>
          {/* <CardRating>
            <Rating star={star} />{' '}
            <span className="product-card__rating-legend">
              ({star.toFixed(1)})
            </span>
            {!isRow && <LikeButton liked={isInterested} productId={id} />}
          </CardRating> */}
          {/* <CardText>{CARD_TEXT.FREESHIP}</CardText> */}
          {!isRow && (
            <ProductAction
              disabled={disabled}
              isSubmitting={isSubmitting}
              onViewClick={onViewClick}
              onAddCartClick={onAddCartClick}
              onBuyClick={onBuyClick}
            />
          )}
        </CardInfo>
      </CardBody>
      {isRow && (
        <ProductActionInRow
          disabled={disabled}
          isSubmitting={isSubmitting}
          onAddCartClick={onAddCartClick}
          onBuyClick={onBuyClick}
        />
      )}
    </div>
  );
};

export default ProductCard;
