import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

export const INTEREST_PRODUCT_MUTATION = gql`
  mutation InterestProduct($id: ID) {
    interestProduct(id: $id)
  }
`;

export const useInterestProduct = () => {
  return useMutation(INTEREST_PRODUCT_MUTATION);
};

export const DELETE_INTEREST_PRODUCT_MUTATION = gql`
  mutation DeleteInterestProduct($id: ID) {
    deleteInterestedProduct(id: $id)
  }
`;

export const useDeleteInterestProduct = () => {
  return useMutation(DELETE_INTEREST_PRODUCT_MUTATION);
};

export const IS_INTEREST_PRODUCT_MUTATION = gql`
  query IsInterestProduct($id: ID) {
    isInterestedProduct(id: $id)
  }
`;

export const useIsInterestedProduct = (options = {}) => {
  return useQuery(IS_INTEREST_PRODUCT_MUTATION, options);
};
