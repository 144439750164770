import React from 'react';
import styled from 'styled-components';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

import Heart from '@views/static/heart.svg';
import HeartFull from '@views/static/heart-full.svg';

import { useAlert } from 'react-alert';
import {
  useInterestProduct,
  useDeleteInterestProduct,
  useIsInterestedProduct,
} from '../../hooks/use-interest-product';
import _get from 'lodash/get';

const ButtonInterest = styled(Button)`
  color: #858585 !important;
  background: transparent !important;
  border-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:active {
    outline: none !important;
    background: transparent !important;
    border-color: transparent !important;
    color: #ff3945 !important;
  }
`;

const LikeButtonComponent = ({ isAuthorized, productId }) => {
  const alert = useAlert();
  const [isLiked, setIsLiked] = React.useState<boolean>(false);
  const { data } = useIsInterestedProduct({ variables: { id: productId } });
  const [interestProduct] = useInterestProduct();
  const [deleteProduct] = useDeleteInterestProduct();

  React.useEffect(() => {
    if (data) {
      setIsLiked(data.isInterestedProduct);
    }
  }, [data]);

  const handleInterestProduct = React.useCallback(async (id) => {
    try {
      const response = await interestProduct({
        variables: {
          id,
        },
      });
      if (response.data) {
        setIsLiked(true);
      }
    } catch (ex) {
      const message =
        _get(ex, 'message') ||
        _get(ex, 'error.message') ||
        'Có lỗi xảy ra vui lòng thử lại';
      alert.show(message);
    }
  }, []);

  const handleDelete = React.useCallback(
    async (id) => {
      try {
        const response = await deleteProduct({ variables: { id } });
        if (response.data) {
          setIsLiked(false);
        }
      } catch (ex) {
        const message =
          _get(ex, 'message') ||
          _get(ex, 'error.message') ||
          'Có lỗi xảy ra vui lòng thử lại';
        alert.show(message);
      }
    },
    [productId]
  );

  const handleToggleLike = React.useCallback(async () => {
    if (!isAuthorized) {
      alert.show('Bạn cần đăng nhập để thực hiện tính năng này');
      return;
    }
    try {
      if (isLiked) {
        await handleDelete(productId);

        return;
      }

      await handleInterestProduct(productId);
    } catch (ex) {
      console.error(ex);
    }
  }, [productId, isAuthorized, alert, isLiked]);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-interest">Thêm vào yêu thích</Tooltip>}
    >
      <ButtonInterest className="ml-1" onClick={handleToggleLike}>
        {isLiked ? (
          <HeartFull height={24} width={24} />
        ) : (
          <Heart height={24} width={24} style={{ fill: 'currentColor' }} />
        )}
        <span className="ml-2">Yêu thích</span>
      </ButtonInterest>
    </OverlayTrigger>
  );
};

const LikeButton = React.memo(LikeButtonComponent);
LikeButton.displayName = 'LikeButton';

export default LikeButton;
