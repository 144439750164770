import React, { SyntheticEvent } from 'react';
import LazyImage, { LazyLoadImage } from '@views/components/lazy-image';
import cn from 'classnames';

type ProductImageProps = {
  image: string;
  alt?: string;
  hasVideo?: boolean;
  href?: string;
  onClick?: (event: SyntheticEvent) => void;
  [key: string]: any;
};
const ProductImage = React.forwardRef<HTMLAnchorElement, ProductImageProps>(
  ({ image, alt, hasVideo, href, onClick, className, ...otherProps }, ref) => (
    <a
      ref={ref}
      aria-label={alt}
      title={alt}
      href={href}
      onClick={onClick}
      className={cn('product-card__image', className)}
      style={{ position: 'relative' }}
      {...otherProps}
    >
      <div className="product-card__image-block">
        {/* <LazyImage src={image} alt={alt} /> */}
        <LazyLoadImage
          image={{
            src: image,
            alt,
            placeholderSrc: '/assets/default.png',
          }}
        />
      </div>
      {hasVideo && (
        <svg
          style={{
            position: 'absolute',
            display: 'inline-block',
            fill: 'currentColor',
            right: '.25rem',
            bottom: '.25rem',
            width: '20px',
            height: '20px',
            zIndex: 1,
          }}
          enableBackground="new 0 0 15 15"
          viewBox="0 0 15 15"
          x="0"
          y="0"
        >
          <circle cx="7.5" cy="7.5" fill="#040000" opacity=".5" r="7.5" />
          <path
            clipRule="evenodd"
            d="m9.8 8.5v.8c0 .7-.5 1.2-1.1 1.2h-4.6c-.6 0-1.1-.5-1.1-1.2v-3.6c0-.7.5-1.2 1.1-1.2h4.5c.5 0 .9.3 1 .7.1.1.1.3.1.5v.8l2-1.4c.2 0 .3.1.3.3v4.2c0 .2-.1.3-.3.3z"
            fill="#ffffff"
            fillRule="evenodd"
          />
        </svg>
      )}
    </a>
  )
);

export default ProductImage;
