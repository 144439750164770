import React, { memo } from 'react';
import { Card } from 'react-bootstrap';
import Product from '@views/components/product/Product';
import ListStyled from '@views/components/list/ListStyled';
import { companyInfo } from '@mocks/sample-data';
import { INTERNAL_ROUTES } from '@helpers/route';

const IconSale = memo(() => (
  <img
    src="/assets/icon/sale-tag.png"
    width={24}
    height={24}
    alt="icon"
    className="loaded mr-1"
  />
));

const IconCheck = memo(() => (
  <img
    src="/assets/icon/check.png"
    width={16}
    height={16}
    alt="icon"
    className="loaded mr-1"
  />
));

export const Sidebar = memo(() => (
  <Product.Sidebar className="mb-4">
    <div className="mb-4">
      <ListStyled>
        <ListStyled.Item>
          <img
            src="/assets/icon/loudspeaker.png"
            width={25}
            height={25}
            alt={companyInfo.phone}
            className="mr-2"
          />
          <span className="text-tooltip">
            <strong>Nhận báo giá văn phòng phẩm NHANH HƠN</strong>{' '}
          </span>
          <a href={INTERNAL_ROUTES.QUOTATION} className="list__link">
            tại đây
          </a>
        </ListStyled.Item>
        {/* <ListStyled.Item>
          <img
            src="/assets/icon/free-delivery.png"
            width={25}
            height={25}
            alt="Hỗ trợ SHIP nội thành HCM"
            className="mr-2"
          />
          <span className="text-tooltip">
            <strong>MIỄN PHÍ</strong> vận chuyển nội thành <strong>HCM</strong>
          </span>
        </ListStyled.Item> */}

        <ListStyled.Item className="d-md-none">
          <img
            src="/assets/icon/phone.png"
            width={25}
            height={25}
            alt={companyInfo.phone}
            className="mr-2"
          />
          <span>Hotline đặt hàng: </span>
          <a href={`tel:${companyInfo.phone}`} className="list__link">
            {companyInfo.phone}
          </a>
        </ListStyled.Item>
        <ListStyled.Item className="d-md-none">
          <img
            src="/assets/icon/gmail.png"
            width={25}
            height={25}
            alt={companyInfo.email}
            className="mr-2"
          />
          <span>Email: </span>
          <a href={`mailto:${companyInfo.email}`} className="list__link">
            {companyInfo.email}
          </a>
        </ListStyled.Item>
      </ListStyled>
    </div>

    <Card>
      {/* <Card.Body className="pb-0 grid">
        <Card.Text>Chính sách chiết khấu</Card.Text>
        <ListStyled>
          <ListStyled.Item>
            <IconSale />
            Giảm <strong>5%</strong> và quà tặng khi mua từ{' '}
            <strong>1 triệu</strong>
          </ListStyled.Item>
          <ListStyled.Item>
            <IconSale />
            Giảm <strong>7%</strong> và quà tặng khi mua từ{' '}
            <strong>3 triệu</strong>
          </ListStyled.Item>
          <ListStyled.Item>
            <IconSale />
            Giảm <strong>10%</strong> và quà tặng khi mua từ{' '}
            <strong>5 triệu</strong>
          </ListStyled.Item>
          <ListStyled.Item>
            <IconSale />
            Giảm <strong>15%</strong> và quà tặng khi mua từ{' '}
            <strong>8 triệu</strong>
          </ListStyled.Item>
        </ListStyled>
      </Card.Body>
      <Card.Body className="pt-0 pb-0">
        <hr />
      </Card.Body> */}
      <Card.Body className="pt-0 grid">
        <Card.Text>Lợi ích khi mua hàng</Card.Text>
        <ListStyled>
          <ListStyled.Item>
            <IconCheck />
            <strong>Đổi trả dễ dàng</strong> theo yêu cầu
          </ListStyled.Item>
          <ListStyled.Item>
            <IconCheck />
            Ưu tiên khách hàng <strong>V.I.P</strong>
          </ListStyled.Item>
        </ListStyled>
      </Card.Body>
    </Card>
  </Product.Sidebar>
));

export default Sidebar;
