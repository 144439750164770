import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export default function (itemid) {
  return useQuery(
    gql`
      query GetShopeeVideo($itemid: String) {
        shopeeItem(itemid: $itemid) {
          videoUrl
        }
      }
    `,
    {
      variables: {
        itemid,
      },
      ssr: false,
    }
  );
}
