import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';

import { tracking } from '../../lib/tracking';
import useTrackingProduct from '../../hooks/use-tracking-product';

import { useLayoutContext } from '@views/layouts';

export const TrackingFetcher = ({ productId }) => {
  const { me } = useLayoutContext();
  const [trackingProduct] = useTrackingProduct();
  const cookies = new Cookies();

  useEffect(() => {
    tracking(cookies, productId);
  }, [cookies, productId]);

  useEffect(() => {
    if (me.size > 0 && productId) {
      trackingProduct({
        variables: {
          id: productId,
          customerId: parseInt(me.get('id')),
        },
      });
    }
  }, [me, productId, trackingProduct]);

  return null;
};

export default TrackingFetcher;
