import React, { Fragment, useState, useCallback } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { Modal, Alert } from 'react-bootstrap';

import { Block } from '@views/components/block';
import Product from '@views/components/product/Product';
import Gallery from '@views/modules/product/components/product-content/components/gallery';

import { useProductById } from '@views/modules/product/hooks/use-product-detail';
import useShopeeVideo from '@views/modules/product/hooks/use-shopee-video';
import TrackingFetcher from '@views/modules/recent-view/components/tracking-component';

import { formatCurrencyVN as formatCurrency } from '@helpers/format-currency';
import _percentage from '@helpers/percentage';
import _decodeHTML from '@helpers/decode-html';
import _get from 'lodash/get';
import _randomNumber from '@helpers/random-number';
import _isContactPrice from '@helpers/is-contact-price';

import toUrl from '@helpers/convert-to-url';
import brandCache from '@cache/brand';
import { INTERNAL_URI, INTERNAL_ROUTES, brandHref } from '@helpers/route';
import ListStyled from '@views/components/list/ListStyled';
import { companyInfo } from '@mocks/sample-data';
import Sidebar from '@views/modules/product/components/product-content/components/sidebar';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

import Action from './action';
import { MODAL_TEXT } from '../../intl';

const MAX_RATING = 5;
const DetailButton = styled.button`
  border: 0;
  outline: none !important;
  color: var(--blue);
  fill: var(--blue);
  &:active {
    border: 0;
    outline: none !important;
  }
`;
const BrandTitle = styled.h6``;

const ProductModal = ({ id, onClose, onSubmit, isAuthorized }) => {
  const { product } = useProductById(id);
  const { data } = useShopeeVideo(product.get('shopeeId'));

  let galleries = product.getIn(['attributes', 'galleries']) || [];
  if (product.get('video')) {
    galleries = [
      {
        id: 'youtube',
        type: 'youtube',
        value: product.get('video'),
        thumbnail: '/assets/icon/play.svg',
      },
      ...galleries,
    ];
  } else if (product.get('shopeeId') && !!_get(data, 'shopeeItem.videoUrl')) {
    galleries = [
      {
        id: 'shopee',
        type: 'shopee',
        value: _get(data, 'shopeeItem.videoUrl'),
        thumbnail: '/assets/icon/play.svg',
      },
      ...galleries,
    ];
  }

  const name = product.get('name') || '';

  const price = product.get('price', 0);
  const listPrice = parseInt(product.getIn(['attributes', 'listPrice']) || 0);
  const sale: number = !!listPrice ? listPrice - price : 0;

  const quantityAvailable = product.get('quantity', 0);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const onShowDetail = useCallback(() => {
    setShowDetail(true);
  }, []);
  const star = MAX_RATING;

  const isContact = product.get('isContactProduct');
  const showAction = quantityAvailable > 0;
  const getContentPrice = () => {
    if (quantityAvailable <= 0) {
      return (
        <Product.Price.NewPrice>
          {MODAL_TEXT.OUT_OF_STOCK}
        </Product.Price.NewPrice>
      );
    }

    if (isContact) {
      return (
        <Product.Price.NewPrice>{MODAL_TEXT.CONTACT}</Product.Price.NewPrice>
      );
    }

    return (
      <>
        <Product.Price.NewPrice>{formatCurrency(price)}</Product.Price.NewPrice>
        {!!sale && (
          <>
            <Product.Price.BadgeSale>
              -{_percentage(price, listPrice)}%
            </Product.Price.BadgeSale>
            <Product.Price.OldPrice>
              {formatCurrency(listPrice)}
            </Product.Price.OldPrice>
          </>
        )}
      </>
    );
  };
  const brand = product.getIn(['attributes', 'brand']);

  return (
    <Modal show onHide={onClose} className="modal-mobile-full" size="xl">
      <Modal.Body className="modal__body modal__body--form">
        <TrackingFetcher productId={id} />
        <div className="d-flex justify-content-between mb-4">
          <Modal.Title />
          <button type="button" onClick={onClose} className="icon-close" />
        </div>

        <Block>
          <Product modal>
            <Product.Content>
              <Gallery data={galleries} />
              <Product.Info>
                {!!brand && (
                  <BrandTitle>
                    Thương hiệu:{' '}
                    <Link
                      href={INTERNAL_URI.BRAND}
                      as={brandHref(
                        brand.get('id'),
                        _get(
                          brandCache[brand.get('id')],
                          'slug',
                          toUrl(brand.get('value'))
                        )
                      )}
                    >
                      <a
                        className={cn(EVENT_CLASS_NAME)}
                        data-category={EVENT_CATEGORY.BRAND}
                        data-action={EVENT_ACTION_NAME}
                        data-label={brand.get('value')}
                      >
                        {brand.get('value')}
                      </a>
                    </Link>
                  </BrandTitle>
                )}
                <Product.Name>{name}</Product.Name>

                {/* <Product.Rating>
                  <Rating star={star} />{' '}
                  <span className="product-card__rating-legend">({star})</span>
                </Product.Rating> */}
                <Product.Price>{getContentPrice()}</Product.Price>

                {showAction && (
                  <Action
                    id={id}
                    isAuthorized={isAuthorized}
                    quantityAvailable={quantityAvailable}
                    onSubmit={onSubmit}
                  />
                )}
                {isContact && (
                  <Alert variant="warning">
                    <small>
                      <em>{MODAL_TEXT.CONTACT_PRICE_MESSAGE}</em>
                    </small>
                  </Alert>
                )}
                <div className="mb-4">
                  <Sidebar />
                </div>
                {!showDetail && (
                  <DetailButton onClick={onShowDetail}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 10.5 14"
                    >
                      <path
                        d="M7,0l7,10.5H0Z"
                        transform="translate(10.5) rotate(90)"
                      />
                    </svg>
                    <span>{MODAL_TEXT.VIEW_DESCRIPTION}</span>
                  </DetailButton>
                )}
              </Product.Info>
            </Product.Content>
          </Product>
        </Block>

        {showDetail && (
          <Block isSection className="mt-4">
            <Block.Header>
              <Block.Title>{MODAL_TEXT.DESCRIPTION}</Block.Title>
            </Block.Header>
            <Block.Body className="mt-3">
              <Block.Html>
                {/* {ReactHtmlParser(
                  _decodeHTML(
                    product.getIn(['attributes', 'description']) || ''
                  )
                )} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: product.getIn(['attributes', 'description']),
                  }}
                />
              </Block.Html>
            </Block.Body>
          </Block>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
