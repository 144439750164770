import React, { Component } from 'react';
import ProductComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export class ListStyledItem extends Component<TParentComponentProps> {
  static defaultProps = {
    as: 'li',
    defaultClassName: 'list__item',
  };
  render(): JSX.Element {
    return <ProductComponent {...this.props} />;
  }
}

export class ListStyledText extends Component<TParentComponentProps> {
  static defaultProps = {
    as: 'div',
    defaultClassName: 'list__text',
  };
  render(): JSX.Element {
    return <ProductComponent {...this.props} />;
  }
}

export type TListStyledProps = TParentComponentProps & {};

export class ListStyled extends Component<TListStyledProps> {
  static defaultProps = {
    as: 'ul',
    defaultClassName: 'list list--styled',
  };
  static Item = ListStyledItem;
  static Text = ListStyledText;

  render(): JSX.Element {
    return <ProductComponent {...this.props} />;
  }
}

export default ListStyled;
