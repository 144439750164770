import React from 'react';
import ReactImageMagnify from 'react-image-magnify';

const PLACEHOLDER = '/assets/default.png';
function useData() {
  const [isSmallImageError, setIsSmallImageError] = React.useState<boolean>(
    false
  );
  const onSmallImageError = React.useCallback(() => {
    setIsSmallImageError(true);
  }, [setIsSmallImageError]);

  const [isLargeImageError, setIsLargeImageError] = React.useState<boolean>(
    false
  );
  const onLargeImageError = React.useCallback(() => {
    setIsLargeImageError(true);
  }, [setIsLargeImageError]);

  return {
    isSmallImageError,
    onSmallImageError,
    isLargeImageError,
    onLargeImageError,
  };
}

export const Magnify = React.memo<any>((props) => {
  const {
    isSmallImageError,
    onSmallImageError,
    isLargeImageError,
    onLargeImageError,
  } = useData();

  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          alt: props.alt,
          isFluidWidth: true,
          src: isSmallImageError ? PLACEHOLDER : props.src,
          onError: onSmallImageError,
        },
        largeImage: {
          alt: props.alt,
          src: isLargeImageError ? PLACEHOLDER : props.largeSrc,
          width: 1200,
          height: 600,
          onError: onLargeImageError,
        },
      }}
    />
  );
});

export default Magnify;
